export const realtorData = {
  email: 'carvalpropiedades@unne.cl',
  phone:'56933730414'
  // email: 'contacto@accionpanal.com',
};

export const footerData = {
  email: 'carvalpropiedades@unne.cl',
  phone:'933730414',
  wsp:'933730414',
}
